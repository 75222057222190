
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";

interface CardData {
  nameOnCard: string;
  cardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  cvv: string;
}

export default defineComponent({
  name: "new-card-modal",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  setup() {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    const cardData = ref<CardData>({
      nameOnCard: "Max Doe",
      cardNumber: "4111 1111 1111 1111",
      expirationMonth: "",
      expirationYear: "",
      cvv: ""
    });

    const validationSchema = Yup.object().shape({
      nameOnCard: Yup.string()
        .required()
        .label("Name"),
      cardNumber: Yup.string()
        .required()
        .label("Card number"),
      expirationMonth: Yup.string()
        .required()
        .label("Month"),
      expirationYear: Yup.string()
        .required()
        .label("Year"),
      cvv: Yup.string()
        .required()
        .label("CVV")
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      setTimeout(() => {
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;

          submitButtonRef.value?.removeAttribute("data-kt-indicator");
        }

        Swal.fire({
          text: "Form has been successfully submitted!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        }).then(() => {
          window.location.reload();
        });
      }, 2000);
    };

    return {
      cardData,
      validationSchema,
      submit,
      submitButtonRef
    };
  }
});
